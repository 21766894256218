/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosRequestConfig } from 'axios'
import { isArray, isPlainObject } from 'lodash'

import Info from 'src/info'
import { generateAuthenticationHeaders } from 'src/services/authentication'
import { CountriesResponse } from 'src/types/Countries'
import { EnforcementLotParkingSessionsVariantResponse } from 'src/types/Property'
import { CurrentSessionResponse } from 'src/types/Session'
import {
  ChangePasswordProps,
  ChangePasswordResponse,
  LoginResponse,
  MeResponse,
  PasswordRulesResponse,
  ResetPasswordProps,
  ResetPasswordResponse,
  SendPasswordCodeProps,
  SendPasswordCodeResponse,
} from 'src/types/User'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,

  transformRequest: [
    (data: Record<string, any>, headers: Record<string, any>): any => {
      headers['Content-Type'] = 'application/json'
      headers['Citifyd-app-version'] = `Live ${Info.version}`
      headers['Citifyd-accept-language'] = 'en-us'

      if (!headers['Authorization']) {
        const authHeaders = generateAuthenticationHeaders()

        for (const header in authHeaders) {
          if (!(header in headers)) headers[header] = authHeaders[header]
        }
      }

      return isPlainObject(data) || isArray(data) ? JSON.stringify(data) : data
    },
  ],
})

export const renewCurrentSession = () =>
  api
    .put<CurrentSessionResponse>('/me/sessions/current')
    .then(response => response.data)

export const newSession = ({ data, headers }: AxiosRequestConfig) =>
  api
    .post<CurrentSessionResponse>('/me/sessions/current/new', data, {
      headers,
    })
    .then(response => response.data)

// Authentication
export const getMe = (data: AxiosRequestConfig) =>
  api.get<MeResponse>('/me', data).then(response => response.data?.user)

export const login = ({ data, ...options }: AxiosRequestConfig) =>
  api
    .post<LoginResponse>('/login', data, options)
    .then(response => response.data)

export const requestUserPasswordReset = (data: ResetPasswordProps) =>
  api
    .post<ResetPasswordResponse>('/password', data)
    .then(response => response?.data)

// export const verifyAccount = ({
//   phoneCountryCode,
//   phoneNumber,
//   code,
// }: VerifyAccountProps) =>
//   api.post<VerifyAccountResponse>(
//     `phone-numbers/${phoneCountryCode}/${phoneNumber}/verify`,
//     code,
//   )

export const getPasswordRules = () =>
  api
    .get<PasswordRulesResponse>('password-rules')
    .then(response => response.data?.passwordRules)

export const sendPasswordCode = ({
  code,
  queryString,
}: SendPasswordCodeProps) =>
  api
    .get<SendPasswordCodeResponse>(`password/codes/${code}?${queryString}`)
    .then(response => response.data)

export const changePassword = (data: ChangePasswordProps) =>
  api
    .put<ChangePasswordResponse>('password', data)
    .then(response => response.data)

export const getCountries = (data?: AxiosRequestConfig) =>
  api.get<CountriesResponse>('countries', data).then(response => response.data)

export const getLotParkingSessionsVariant = (lotId: string) =>
  api
    .get<EnforcementLotParkingSessionsVariantResponse>(
      `enforcement/tickets/${lotId}/vehicles`,
    )
    .then(response => response.data)
