/* eslint-disable @typescript-eslint/no-floating-promises */
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

type History = {
  back(data: string): void
  history: string[]
  setHistory(data: string[]): void
}

const DEFAULT_VALUE = {
  setHistory: () => null,
  back: () => null,
  history: [],
}

const HistoryContext = createContext<History>(DEFAULT_VALUE)

export const HistoryProvider: React.FC = ({ children }) => {
  const { asPath, push } = useRouter()
  const [history, setHistory] = useState<string[]>([])

  const back = (backState?: string) => {
    // for (let i = history.length - 2; i >= 0; i--) {
    //   const route = history[i]

    //   if (route !== asPath) {
    //     push(route)
    //     const newHistory = history.slice(0, i)
    //     setHistory(newHistory)

    //     return
    //   }
    // }

    if (backState) {
      push(backState)
    }
  }

  useEffect(() => {
    setHistory(previous => [...previous, asPath])
  }, [asPath])

  return (
    <HistoryContext.Provider
      value={{
        back,
        history,
        setHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export const useHistory = (): History => {
  const context = useContext(HistoryContext)

  return context
}
