import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import 'src/styles/global.css'
import React, { createContext, useState } from 'react'

import { useApollo } from 'src/graphql/apolloClient'
import { HistoryProvider } from 'src/hooks/useHistory'

type Message = { content?: string; type?: string }

export type AppContext = {
  message: Message
  setMessage: React.Dispatch<React.SetStateAction<Message>>
}

const DEFAULT_VALUE = {
  setMessage: () => null,
  message: { type: '', content: '' },
}

export const AppCtx = createContext<AppContext>(DEFAULT_VALUE)

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps)

  const [message, setMessage] = useState<Message>(DEFAULT_VALUE.message)

  return (
    <ApolloProvider client={client}>
      <HistoryProvider>
        <AppCtx.Provider
          value={{
            message,
            setMessage,
          }}
        >
          <Component {...pageProps} />
        </AppCtx.Provider>
      </HistoryProvider>
    </ApolloProvider>
  )
}

export default App
