'use strict'
import Cookies from 'js-cookie'
import { GetServerSidePropsContext } from 'next'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { v4 as uuidv4 } from 'uuid'

import { CurrentSessionResponse } from 'src/types/Session'
import { Login, User } from 'src/types/User'

import { getMe, login, newSession, renewCurrentSession } from '../api'

const ACCESS_TOKEN_COOKIE = 'citifyd_access_token'
const ACCESS_CLIENT_TOKEN_COOKIE = 'citifyd_client_token'

let sessionRenewalInterval: ReturnType<typeof setTimeout> = null
let accessToken: string
let clientToken: string
let serverContext: GetServerSidePropsContext

const getClientToken = (): string => {
  if (serverContext) {
    clientToken = parseCookies(serverContext)[ACCESS_CLIENT_TOKEN_COOKIE]
  } else {
    serverContext = null
    clientToken = Cookies.get(ACCESS_CLIENT_TOKEN_COOKIE)
  }

  if (!clientToken) {
    clientToken = uuidv4()

    if (serverContext) {
      setCookie(serverContext, ACCESS_CLIENT_TOKEN_COOKIE, clientToken, {
        path: '/',
      })
    } else {
      Cookies.set(ACCESS_CLIENT_TOKEN_COOKIE, clientToken)
    }
  }

  return clientToken
}

const startSessionRenewalInterval = () => {
  endSessionRenewalInterval()

  sessionRenewalInterval = setInterval(
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    () => renewSession(),
    10 * 60 * 1000, // 10 minutes
  )
}

const endSessionRenewalInterval = () => {
  if (sessionRenewalInterval !== null) {
    clearInterval(sessionRenewalInterval)
    sessionRenewalInterval = null
  }
}

export const getAccessToken = (): string => {
  if (serverContext) {
    accessToken = parseCookies(serverContext)[ACCESS_TOKEN_COOKIE]
  } else {
    serverContext = null
    accessToken = Cookies.get(ACCESS_TOKEN_COOKIE)
  }

  return accessToken
}

export const reloadUser = async (data?: Record<string, any>): Promise<User> => {
  const user = await getMe({
    ...data,
    params: { loadPermissions: true },
  })

  if (!user) {
    return null
  }

  getClientToken()

  return user
}

export const logout = (context?: GetServerSidePropsContext) => {
  endSessionRenewalInterval()

  if (serverContext) {
    destroyCookie(serverContext, ACCESS_TOKEN_COOKIE)
    destroyCookie(serverContext, ACCESS_CLIENT_TOKEN_COOKIE)
    context.res.statusCode = 302
    context.res.setHeader('Location', `/`)
  } else {
    serverContext = null
    Cookies.remove(ACCESS_TOKEN_COOKIE)
    Cookies.remove(ACCESS_CLIENT_TOKEN_COOKIE)
    location.href = '/'
  }
}

const persistToken = (token: string) => {
  if (serverContext) {
    destroyCookie(serverContext, ACCESS_TOKEN_COOKIE)
    setCookie(serverContext, ACCESS_TOKEN_COOKIE, token, {
      path: '/',
    })
  } else {
    Cookies.set(ACCESS_TOKEN_COOKIE, token)
  }
}

export const tryAuthenticate = async (data: Login) => {
  const headers = {}
  const clientToken = getClientToken()

  if (clientToken) {
    headers['Citifyd-client-token'] = clientToken
  }

  const response = await login({
    headers,
    data: { ...data, sessionType: 'monitoring' },
    params: { loadPermissions: true },
  })

  if (response?.user?.currentSession?.type === 'monitoring') {
    persistToken(response.user?.accessToken)
    startSessionRenewalInterval()
  } else if (response?.user?.currentSession) {
    return null
  }

  return response
}

export const createMonitoringSessionFromManagementSession = async (
  accessToken: string,
): Promise<CurrentSessionResponse> =>
  await newSession({
    data: { session: { sessionType: 'monitoring' } },
    headers: generateAuthenticationHeaders(accessToken?.toString()),
  })

export const verifyAuthentication = async (
  context?: GetServerSidePropsContext,
  accessToken?: string,
): Promise<User> => {
  serverContext = context

  if (!accessToken && context?.query?.token) {
    persistToken(context.query.token?.toString())
    getClientToken()
    startSessionRenewalInterval()
  } else if (!getAccessToken()) {
    return null
  }

  try {
    const user = await reloadUser({
      headers: generateAuthenticationHeaders(
        accessToken || context.query.token?.toString(),
      ),
    })

    if (user?.currentSession?.type !== 'monitoring') {
      const newSession = await createMonitoringSessionFromManagementSession(
        user.accessToken,
      )

      persistToken(newSession?.accessToken?.toString())
      startSessionRenewalInterval()
      await verifyAuthentication(context, newSession?.accessToken?.toString())

      return user
    } else {
      return user
    }
  } catch (err) {
    logout(context)

    return null
  }
}

const renewSession = async (): Promise<boolean> => {
  if (!getAccessToken()) {
    return false
  }

  const response = await renewCurrentSession()

  if (response.renewed) {
    persistToken(response.accessToken)

    return true
  }

  return false
}

export const setToken = async (token: string) => {
  persistToken(token)

  const result = await reloadUser()
  await renewSession()
  startSessionRenewalInterval()

  return result
}

export const generateAuthenticationHeaders = (accessToken?: string) => {
  const headers = {}
  accessToken = accessToken || getAccessToken()

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  const clientToken = getClientToken()

  if (clientToken) {
    headers['Citifyd-client-token'] = clientToken
  }

  return headers
}
